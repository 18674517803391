import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "notification-groups"
    }}>{`Notification Groups`}</h1>
    <p>{`When a token is triggered, Dragnet needs to know who to send the alerts to. This is done via Notification Groups.  When creating a `}<inlineCode parentName="p">{`New Token`}</inlineCode>{` you must select a `}<inlineCode parentName="p">{`Notification Group`}</inlineCode>{`. The `}<inlineCode parentName="p">{`Notification Group`}</inlineCode>{` contains the recipients that will be notified if that token is triggered.`}</p>
    <p>{`After a token is deployed, you can retroactively select a different `}<inlineCode parentName="p">{`Notification Group`}</inlineCode>{` or add and remove individuals from a `}<inlineCode parentName="p">{`Notification Group`}</inlineCode>{` without re-deploying the token.`}</p>
    <h2 {...{
      "id": "create"
    }}>{`Create`}</h2>
    <p>{` Notification Groups are created by navigating to `}<inlineCode parentName="p">{`Notification Groups`}</inlineCode>{` > `}<inlineCode parentName="p">{`New Notification Group`}</inlineCode>{`.  Enter a name for the group, such as `}<inlineCode parentName="p">{`IT Team`}</inlineCode>{`. `}</p>
    <p>{`Add individuals by entering email addresses into the Email field and click `}<inlineCode parentName="p">{`Add`}</inlineCode>{`. You can add multiple recipients to each group`}</p>
    <h3 {...{
      "id": "webhooks--microsoft-teams"
    }}>{`Webhooks / Microsoft Teams`}</h3>
    <p>{`Dragnet features an integration with Microsoft Teams using Teams' Webhooks feature.`}</p>
    <p>{`Within your Teams application, you need to create a Webhook Connector, copy the full URL, including the `}<inlineCode parentName="p">{`https://`}</inlineCode>{`, and paste it into the Dragnet Webhook field.`}</p>
    <h3 {...{
      "id": "saving"
    }}>{`Saving`}</h3>
    <p>{`When creating a `}<inlineCode parentName="p">{`Notification Group`}</inlineCode>{` at least one email address is required.  Webhooks, however, are optional. Once all desired email addresses and/or a webhook have been added, click to `}<inlineCode parentName="p">{`Save`}</inlineCode>{` the `}<inlineCode parentName="p">{`Notification Group`}</inlineCode>{`.  Upon a successful save, the newly created group will be available in the `}<inlineCode parentName="p">{`Notification Group`}</inlineCode>{` dropdown menu in the `}<inlineCode parentName="p">{`New Token`}</inlineCode>{` interface.`}</p>
    <h2 {...{
      "id": "edit"
    }}>{`Edit`}</h2>
    <p>{`To edit a  `}<inlineCode parentName="p">{`Notification Group`}</inlineCode>{`, navigate from the left menu to the  `}<inlineCode parentName="p">{`Edit Notification Group`}</inlineCode>{` interface. When you select the `}<inlineCode parentName="p">{`Notification Group`}</inlineCode>{` you want to edit, the interface presents options to edit the group. It is functionally the same as the new Notification Group.  Edit the values as necessary and save your changes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      